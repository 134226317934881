import React from 'react';
import { ColorModeScript } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from 'react-router-dom'
// import AppHelmet from './AppHelmet'
// import ApolloProvider from './Graphql/client'
import customTheme from "./Theme";
import Fonts from './Theme/fonts'
import Routes from "./Routes";

const App = () => (
  <React.StrictMode>
    <ChakraProvider theme={customTheme} resetCSS={true}>
      <Fonts />
      <ColorModeScript />
        {/* <AppHelmet /> */}
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
)

export default App;
