export const colors = {
  customGray: {
    200: '#E6E6E6',
    300: '#CDCDCD',
    400: '#A1A1A1', 
    500: '#757575',
    600: '#656565',
    700: '#3B3B3B',
    800: '#1A1A1A',
  },
  primary: {
    100: '#E2EFFF',
    200: '#C6DDFF',
    300: '#6E96E8',
    400: '#406FDB',
    500: "#F2FAFF",
    600: '#142C7E',
  },
  warning: '#E26139',
  disabled: '#A1A1A1',
  mainBackground: '#E5E5E5',
  primaryBackground: '#1F1F1F',
  secondaryBackground: '#FFFFFF',
  gradientBackground: 'linear-gradient(90deg, #9352FF 0%, #00FFF0 100%)',
  text1: '#FFFFFF',
  text2: '#1F1F1F',
  text3: '#1A1A1A',
  text4: '#CDCDCD',
  text5: '#757575',
  text6: '#CDCDCD',
  text7: '#CFCFCF',
  divider: '#CDCDCD',
}