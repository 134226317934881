export const textStyles = {
    h1: {
        fontFamily: "Stolzl",
        fontSize: [32],
    },
    h2: {
        fontFamily: "Stolzl",
        fontSize: 24,
        fontWeight: "normal"
    },
    h3: {
        fontFamily: "Stolzl",
        fontSize: [20],
    },
    h4: {
        fontFamily: "Stolzl",
        fontSize: [16],
    },
    h5: {
        fontFamily: "Stolzl",
        fontSize: [14],
    },
    h6: {
        fontFamily: "Stolzl",
        fontSize: [12],
    },
    menuHeading: {
        fontWeight: "400",
        fontSize: [16],
        color: "text5",
    },
    footerHeading: {
        fontFamily: "Stolzl",
        fontWeight: "500", 
    },
    pagesMainHeading: {
        fontFamily: "Stolzl",
        fontWeight: "500",
        textAlign: "left",
    },
    pagesSubHeadingMobile: {
        fontFamily: "Stolzl",
        fontSize: [40],
    },
    pagesSubHeadingLaptop: {
        fontFamily: "Stolzl",
        fontSize: [50],
    },
    pagesSubHeadingDesktop: {
        fontFamily: "Stolzl",
        fontSize: [64],
    },
    menuHover: {
        fontFamily: "Stolzl",
        fontWeight: "500",
        color: "brand.text1",
        fontSize:{ base: 32, lg: 46, xl: 64, "2xl": "4vw" },
        mt:{ base: 5, lg: 8 },
        backgroundClip: "text",
        backgroundSize: "130%", 
        backgroundPosition: "-100%",
        bgGradient: 'linear-gradient(90deg, #9352FF 0%, #00FFF0 100%)',
    },
    formInputHeader: {
        // fontFamily: "John",
        // // fontWeight: "500",
    },
    formInputText: {
        // fontFamily: "Stolzl",
        color: "#B3B3B3",
        pl: "0" ,
        textUnderlineOffset: '8px',
        pb: "5px",
    },
    contactLinks: {
        fontFamily: "Stolzl",
        fontSize: 24,
        fontWeight: "500",
        _hover: {
            backgroundClip: "text",
            backgroundSize: "100%",
            backgroundPosition: "100%",
            bgGradient: "linear-gradient(45deg, #9352FF 0%, #00FFF0 100%)"
        }
    },
    footerLinks: {
        // fontFamily: "Stolzl",
        color: 'text5',
        fontSize: [16],
        _hover: {
            color: 'text2'
        }
    },
}
