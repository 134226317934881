export const formInput = {
    variant: {
      contact: {
        border: "none",
        borderBottom: "2px grey solid",
        borderRadius: "0",
        textStyle: "h2",
        fontSize: 24,
        color: "brand.text1",
        pl: "0",
        bg: "red"
      }
    }
};
