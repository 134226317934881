import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
// import { Helmet } from 'react-helmet'
import { AnimatePresence, motion } from 'framer-motion'
import { Box, keyframes, useBreakpointValue, usePrefersReducedMotion } from "@chakra-ui/react";

const Home = React.lazy(() => import("../Pages/Home"));
const About = React.lazy(() => import("../Pages/About"));
const Projects = React.lazy(() => import("../Pages/Projects"));
const Contact = React.lazy(() => import("../Pages/Contact"));
const NotFound = React.lazy(() => import("../Pages/NotFound"));

// const history = createBrowserHistory()
const animationTime = `2s`;

const Routes = () => {
  const location = useLocation()
  const baseUrl = process.env.REACT_APP_DOMAIN_URL || "localhost:3000"

  // const pageTitle = routeTitle.length > 0 ? `Convergence | ${routeTitle}` : "Convergence | Digital product innovation"
  const [isVisible, setIsVisible] = useState(true)
  const [scrollbarTop, setScrollbarTop] = useState(0);
  const [prevHeight, setPrevHeight] = useState(0);
  const mobileOrDesktop = useBreakpointValue({ base: 'mobile', lg: 'desktop' })
  const timer = () => new Promise(resolve => setTimeout(() => resolve(setIsVisible(false)), 3300))

  //SUBSCRIBERS
  useEffect(() => {
    document.addEventListener('scroll', () => {
      const y = document.body.clientHeight
      const w = window.pageYOffset
      const windowHeight = window.innerHeight
      setScrollbarTop((w / y) * windowHeight);
    })
  }, []);

  useEffect(() => {
    timer()
  }, []);

  const inputs = {
    initial: { y: -20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const moveScrollbar = keyframes`
  from{
      top: -250px;
      left: 1.9%;
  }
  to{
      top: 0px;
      left: 1.9%;
  }`

  const prefersReducedMotion = usePrefersReducedMotion()
  const animation = prefersReducedMotion
    ? undefined
    : `${moveScrollbar} ${animationTime}`


  // scrollbar animation
  // set interval to track changes in page height
  const id = setInterval(() => {
    if (document.body.clientHeight != prevHeight) {
      setPrevHeight(document.body.clientHeight);
    }
    clearInterval(id);
  }, 500);

  // calculate height of scrollbar based on current page
  const barHeight = `${((window.innerHeight / document.body.clientHeight) * 100)}vh`;

  return (
    <>
      {/* <Helmet>
        <title>{pageTitle}</title>
        <link rel="canonical" href={`${baseUrl}${location.pathname}`} />
        <meta name="title" content={pageTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="twitter:title" content={pageTitle} />
      </Helmet> */}
      <Suspense
        fallback={<></>}
      >
        {/* {isVisible &&
        <Box>
          <Flex
            position="fixed"
            w="100vw"
            // h="100vh"
            alignItems="center"
            justifyContent="center"
            zIndex="100000"
            dataTestId="LoadingFlex"
          >
            <AnimateLoad />
          </Flex>
        </Box>
        } */}
        <Box p={0}>
          {/* <AnimatePresence> */}
            {/* <ScrollToTop /> */}
            {/* CUSTOM SCROLLBAR */}
            {mobileOrDesktop === 'mobile' ? (
              <Box>
              </Box>
            ) : (
              <Box animation={animation} position="fixed" zIndex="1000" left="1.9%">
                <Box
                  position="absolute"
                  top={`${scrollbarTop}`}
                  w="4px"
                  h={barHeight}
                  transition={`height ${animationTime}`}
                  bg="divider"
                  left="1.9%"
                  zIndex="1000"
                  opacity="80%"
                >
                </Box>
              </Box>
            )}
            <Switch location={location} key={location.pathname}>
              <Route exact path="/">
                <motion.div
                  variants={inputs}
                  animate="animate" initial="initial"
                >
                  <Home />
                </motion.div>
              </Route>
              <Route path="/about">
                <motion.div
                  variants={inputs}
                  animate="animate" initial="initial"
                >
                  <About />
                </motion.div>
              </Route>
              <Route path="/projects">
                <motion.div
                  variants={inputs}
                  animate="animate" initial="initial"
                >
                  <Projects />
                </motion.div>
              </Route>
              <Route path="/contact">
                <motion.div
                  variants={inputs}
                  animate="animate" initial="initial"
                >
                  <Contact />
                </motion.div>
              </Route>
              <Route>
                <motion.div
                  variants={inputs}
                  animate="animate" initial="initial"
                >
                  <NotFound />
                </motion.div>
              </Route>
            </Switch>
          {/* </AnimatePresence> */}
        </Box>
      </Suspense>
    </>
  );
};

export default Routes;

