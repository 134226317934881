import { Global } from '@emotion/react'
import React from 'react'

const Fonts = () => (
  <Global
    styles={`
    @import url("https://p.typekit.net/p.css?s=1&k=sun0bch&ht=tk&f=32205.32206.32207&a=65326927&app=typekit&e=css");
    
    @font-face {
    font-family:"stolzl";
    src:url("https://use.typekit.net/af/148b9c/00000000000000007735affe/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
    url("https://use.typekit.net/af/148b9c/00000000000000007735affe/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
    url("https://use.typekit.net/af/148b9c/00000000000000007735affe/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;
    }
    
    @font-face {
    font-family:"stolzl";
    src:url("https://use.typekit.net/af/505ea4/00000000000000007735b006/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),
    url("https://use.typekit.net/af/505ea4/00000000000000007735b006/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),
    url("https://use.typekit.net/af/505ea4/00000000000000007735b006/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;
    }
    
    @font-face {
    font-family:"stolzl";
    src:url("https://use.typekit.net/af/11d803/00000000000000007735b005/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),
    url("https://use.typekit.net/af/11d803/00000000000000007735b005/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),
    url("https://use.typekit.net/af/11d803/00000000000000007735b005/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
    }
    
    .tk-stolzl { font-family: "stolzl",sans-serif; }

    /* Font Big John */
      @font-face {
          font-family: 'John';
          src: url('../../assets/BIGJOHN.otf');
          font-style: normal;
      }

      /* Font Archivo */
      @font-face {
          font-family: 'Archivo';
          src: url('../../assets/Archivo-Regular.ttf') format('truetype');
          font-style: normal;
      }

      /* Font Archivo Bold*/
      @font-face {
          font-family: 'ArchivoBold';
          src: url('../../assets/Archivo-Bold.ttf') format('truetype');
          font-style: bold;
      }

      /* Wire One*/
      @font-face {
        font-family: 'WireOne';
        src: url('https://fonts.googleapis.com/css2?family=Wire+One&display=swap');
        sans-serif;
      }

      /* Manrope */
      @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap')

      /* Neue Metana Regular */
      @font-face {
        font-family: 'NeueMetanaRegular';
        src: url('src/Assets/Fonts/NeueMetana-Regular.otf') format('opentype');
        font-style: normal;
      }

      /* Neue Metana Bold */
      @font-face {
        font-family: 'NeueMetanaBold';
        src: url('src/Assets/Fonts/NeueMetana-Bold.otf') format('opentype');
        font-style: bold;
      }
    `}
    
  />
)

export default Fonts
