import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { buttons } from "./buttons";
import { formInput } from './formInput';
import { textStyles } from './textStyles'

const customTheme = extendTheme({
  colors,
  buttons,
  formInput,
  textStyles,
});

export default customTheme;
